import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import ToastMessagesComponent  from './ToastMessagesComponent';
import { removeMessage } from './redux/actions';

const mapStateToProps = state => {
    const { toastMessagesReducer } = state;

    return {
        messages: toastMessagesReducer.messages
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ removeMessage }, dispatch)
};

export default connect(
    mapStateToProps,
    mapDispatchToProps, null, {pure:false}
)(ToastMessagesComponent);

