import React, {Component} from 'react';
import queryString from "query-string";
import {Redirect} from 'react-router-dom';

class HomeComponent extends Component {
    componentWillMount() {
        const { location, validateToken } = this.props;
        const values = queryString.parse(location.search);

        if (values.key) {
            validateToken(values.key);
        }
    }

    render() {
        const { loginId, tokenValid } = this.props;

        if (tokenValid && loginId) {
            return <Redirect to="/reset-password" />;
        }

        return "";
    }
}

export default HomeComponent;
