import React from 'react';
import PropTypes from "prop-types";
import * as moment from 'moment';

const TimeSlotComponent = function({timeFrom, timeTo, selected, onSelect, name}){
    return (
        <div className="row">
            <div className="col-12 pointer-cursor card card-time-slot" onClick={() => onSelect(name)}>
                <div className="card-body">
                    <div className="row col-12">
                        <div className="col-10 text-md-left"><p>{moment(timeFrom, "HH:mm:ss").format("hh:mm A")} - {moment(timeTo, "HH:mm:ss").format("hh:mm A")}</p></div>
                        <div className="col-2 text-md-right"><input type="radio" checked={selected} onChange={() => {}} /></div>
                    </div>
                </div>
            </div>
        </div>)
};

TimeSlotComponent.propTypes = {
    timeFrom: PropTypes.string.isRequired,
    timeTo: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired
};

export default TimeSlotComponent;
