import { combineReducers } from "redux";
import { schedulerReducer } from './app/scheduler/redux/reducer';
import { userReducer } from './app/user/redux/reducer';
import { reducer as formReducer } from 'redux-form';
import { toastMessagesReducer } from './app/common/toastmessages/redux/reducer';
import { errorReducer } from './app/common/error/redux/reducer';
import { createForms } from 'react-redux-form';

const initialUserState = {
  firstName: "",
  lastName: "",
  line1: "",
  line2: "",
  city: "",
  state: "",
  postalCode: "",
  emailAddress: "",
  phoneNumber: "",
  password: ""
};

export default combineReducers({
    schedulerReducer,
    userReducer,
    errorReducer,
    toastMessagesReducer,
    form: formReducer,
    ...createForms({
      user: initialUserState,
    }),
});
