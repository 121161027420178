import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./rootReducer";
import logger from 'redux-logger'
import thunk from 'redux-thunk';

let middleware = [ thunk, logger ];
//if (process.env.REACT_APP_STAGE === 'dev') {
//    middleware = [...middleware, logger];
//}

export default createStore(
    rootReducer,
    compose(applyMiddleware(...middleware)));