import React from 'react';
import PropTypes from "prop-types";
import TimeSlotComponent from "./timeslot/TimeSlotComponent";

const TimeSlotsComponent = ({times, onSelect}) => (
    <div className="col-12">
        {
            times && times.length ?
                times.map((item, index) => {
                        return (
                            <TimeSlotComponent timeFrom={item.timeFrom}
                                               timeTo={item.timeTo}
                                               selected={item.selected}
                                               onSelect={onSelect}
                                               key={index}
                                               name={item.name} />
                        );
                    })

                : "No time slots available"
        }
    </div>
);

TimeSlotsComponent.propTypes = {
    times: PropTypes.arrayOf(
        PropTypes.shape({
            timeFrom: PropTypes.string.isRequired,
            timeTo: PropTypes.string.isRequired,
            selected: PropTypes.bool.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
    onSelect: PropTypes.func.isRequired
};

export default TimeSlotsComponent;
