import React, {Component} from 'react';
import './ToastMessages.css';
import ToastMessage from "./toastmessage";
import PropTypes from "prop-types";

class ToastMessagesComponent extends Component {

    render() {
        const {messages, removeMessage} = this.props;

        if (!messages || messages.length < 1) {
            return "";
        }

        return (
            <div className="toast-messages">
                {messages.map((message) => {
                    return (
                        <ToastMessage
                            key={message.id}
                            id={message.id}
                            text={message.text}
                            type={message.type}
                            autoClose={message.autoClose}
                            removeMessage={removeMessage} />
                    );
                })}
            </div>
        );
    }
}

ToastMessagesComponent.propTypes = {
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            autoClose: PropTypes.bool.isRequired
        }).isRequired
    ).isRequired,
    removeMessage: PropTypes.func.isRequired
};

export default ToastMessagesComponent;
