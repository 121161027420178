import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import HomeComponent from './HomeComponent';
import {validateToken} from './../user/redux/actions';

const mapStateToProps = state => {
    const { userReducer } = state;
    return {
        loginId: userReducer.loginId,
        tokenValid: userReducer.tokenValid
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ validateToken }, dispatch)
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeComponent);
