const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const CHANGE_PASSWORD = "CHANGE_PASSWORD";
const RESET_PASSWORD = "RESET_PASSWORD";
const RESET_PASSWORD_SUCCESSFUL = "RESET_PASSWORD_SUCCESSFUL";
const REQUEST_PASSWORD_RESET = "REQUEST_PASSWORD_RESET";
const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE";
const VALIDATE_TOKEN = "VALIDATE_TOKEN";
const CLEAR_TOKEN_INFO = "CLEAR_TOKEN_INFO";
const REGISTER_REQUEST = "USER_REGISTER_REQUEST";
const AGENT_ACCESS = "AGENT_ACCESS";

export default {
    LOGIN,
    LOGOUT,
    CHANGE_PASSWORD,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESSFUL,
    REQUEST_PASSWORD_RESET,
    CLEAR_ERROR_MESSAGE,
    VALIDATE_TOKEN,
    CLEAR_TOKEN_INFO,
    REGISTER_REQUEST,
    AGENT_ACCESS
};
