import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import InputField from './../../common/inputfield';
import config from '../../../config';
import utils from '../../utils';

class ResetPasswordComponent extends Component {

    validate = (password, confirmPassword) => {
        let error = utils.validatePasswordFormat(password, "New password");
        if (error) {
            return error;
        }

        error = utils.validatePasswordFormat(confirmPassword, "Confirm new password");
        if (error) {
            return error;
        }

        error = utils.validatePasswordMatching(password, confirmPassword);
        if (error) {
            return error;
        }
        return "";
    };

    onSubmit = (values) => {
        const { loginId, resetPassword, tokenValid } = this.props;
        let error = this.validate(values.password, values.confirmPassword);

        if (error !== "") {
            this.props.addErrorMessage(error);
        } else {
            if (tokenValid && loginId) {
                resetPassword(loginId, values.password);
            } else {
                this.props.addErrorMessage("Token is invalid");
            }
        }
    };

    componentWillUnmount() {
        const { clearTokenInfo } = this.props;
        clearTokenInfo();
    }

    render() {
        const { passwordReset } = this.props;

        if (passwordReset === true) {
            window.location = config.api.LOGIN_URL;
        }

        return (
            <div className="container">
                <div className="row">
                    <div className="col-6 offset-3 my-5">
                        <h1 className="text-center">Reset Password</h1>
                        <form id="reset-form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                            <div className="form-group">
                                <Field
                                    name="password"
                                    type="password"
                                    component={InputField}
                                    label="New Password"
                                />
                            </div>
                            <div className="form-group">
                                <Field
                                    name="confirmPassword"
                                    type="password"
                                    component={InputField}
                                    label="Confirm New Password"
                                />
                            </div>
                            <button type="submit" className="btn btn-primary w-100">Reset</button>
                        </form>
                        <a className="fs-16 mb-3 d-block blue text-center" href={config.api.STORE_URL}>Cancel</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default reduxForm({
    form: 'resetPassword'
})(ResetPasswordComponent);
