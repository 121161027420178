import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import FooterLink from "../footer/footerlink";
import config from '../../config';

const FooterComponent = () => (
    <footer className="text-light">
        <Container>
            <Row className="mb-12">
                <Col md="12" className="col-12">
                    <Row className="footer-items">
                        <Col md="2" className="col-12 col-md-2">
                            <p className="mb-1 clr-white"><strong>Services</strong></p>
                            <FooterLink title="Smart Thermostat Installation" url={`${config.api.STORE_URL}pages/smart-thermostat-installation`}/>
                            <FooterLink title="TV Mounting & Installation" url={`${config.api.STORE_URL}pages/tv-mounting-installation`}/>
                            <FooterLink title="Home Wifi Network Setup" url={`${config.api.STORE_URL}pages/home-wifi-network-setup`}/>
                            <FooterLink title="Home Audio Installation" url={`${config.api.STORE_URL}pages/home-audio-installation`}/>
                        </Col>
                        <Col md="2" className="col-12 col-md-2">
                            <p className="mb-1 clr-white"><strong>Help Desk</strong></p>
                            <FooterLink title="Contact Us" url={`${config.api.STORE_URL}pages/contact-us`}/>
                            <FooterLink title="Service Appointments" url={`${config.api.STORE_URL}pages/service-appointments`}/>
                            <FooterLink title="Returns & Exchanges" url={`${config.api.STORE_URL}pages/returns`}/>
                            <FooterLink title="Shipping & Delivery" url={`${config.api.STORE_URL}pages/shipping`}/>
                        </Col>
                        <Col md="2" className="col-12 col-md-2">
                            <p className="mb-1 clr-white"><strong>Company</strong></p>
                            <FooterLink title="About us" url={`${config.api.STORE_URL}pages/about-us`}/>
                            <FooterLink title="For Business" url={`${config.api.STORE_URL}pages/for-business`}/>
                        </Col>
                        <Col md="6" className="col-12 col-md-6">
                            <p className="mb-1 clr-white footer-expert text-md-right"><strong>Talk To An Expert</strong></p>
                            <p className="mb-1 clr-white footer-expert text-md-right"><strong>1-833-ONTECH1</strong></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mt-md-4 copy">
                <Col md="12" className="col-12 col-md-12 footer-items">
                    <small>&copy;2019, <a href={config.api.STORE_URL} title=""> OnTech Smart Services</a> | <a href={`${config.api.STORE_URL}pages/terms-conditions`} title="">Terms & Conditions</a> | <a href={`${config.api.STORE_URL}pages/privacy`} title="">Privacy</a></small>
                </Col>
            </Row>
        </Container>
    </footer>
);

export default FooterComponent;
