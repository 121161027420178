const SELECT_DAY = "SELECT_DAY";
const SELECT_TIME = "SELECT_TIME";
const GET_CAPACITIES = "GET_CAPACITIES";
const SELECT_EARLIEST_AVAILABLE = "SELECT_EARLIEST_AVAILABLE";
const RESCHEDULED = "RESCHEDULED";

export default {
    SELECT_DAY,
    SELECT_TIME,
    GET_CAPACITIES,
    SELECT_EARLIEST_AVAILABLE,
    RESCHEDULED
};