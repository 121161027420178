const INFO_MESSAGE = 'info';
const WARN_MESSAGE = 'warn';
const ERROR_MESSAGE = 'error';
const SUCCESS_MESSAGE = 'success';

export default {
    INFO_MESSAGE,
    WARN_MESSAGE,
    ERROR_MESSAGE,
    SUCCESS_MESSAGE
};

