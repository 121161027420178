import types from './types';
import axios from "axios";
import config from './../../../config';
import {reset} from 'redux-form';
import { addErrorMessage, addSuccessMessage } from './../../common/toastmessages/redux/actions';
import utils from './../../utils';

const LOGIN_REST_API = `${config.api.CUSTOMER_MANAGER_REST_API}/login`;
const RESET_PASSWORD_API = `${config.api.CUSTOMER_MANAGER_REST_API}/password-reset`;
const REQUEST_PASSWORD_RESET_API = `${config.api.CUSTOMER_MANAGER_REST_API}/password-reset-email`;
const CHANGE_PASSWORD_API = `${config.api.CUSTOMER_MANAGER_REST_API}/password-change`;
const VALIDATE_TOKEN_API = `${config.api.CUSTOMER_MANAGER_REST_API}/token-validate`;
const REGISTRATION_REST_API = `${config.api.CUSTOMER_MANAGER_REST_API}/registration`;
const AGENT_ACCESS_API = `${config.api.AGENT_ACCESS_API_BASE}/loginAsUser`;

export function login(email, password) {
    return function (dispatch) {
        const transport = axios.create({
            withCredentials: true
        });
        transport.post(
            LOGIN_REST_API,
            {
                password: password,
                username: email
            })
            .then(response => {
                dispatch({
                    type: types.LOGIN,
                    payload: response.data
                });
                dispatch(reset('login'));

                dispatch(addSuccessMessage("User is logged in"));
            })
            .catch((error) => {
                dispatch(addErrorMessage(utils.clearErrorMessage(error.response)));
            });
    }
}

export function logout() {
    return function (dispatch) {
        dispatch({
            type: types.LOGOUT
        });

        dispatch(addSuccessMessage("User is logged out"));
    }
}

export function resetPassword(loginId, password) {
    return function (dispatch) {
        axios.post(
            RESET_PASSWORD_API,
            {
                loginId: loginId,
                password: password
            })
            .then(() => {
                dispatch(reset('resetPassword'));

                dispatch({
                    type: types.RESET_PASSWORD_SUCCESSFUL
                });
            })
            .catch((error) => {
                dispatch(addErrorMessage(utils.clearErrorMessage(error.response)));
            });
    }
}

export function requestPasswordReset(emailAddress) {
    return function (dispatch) {
        axios.post(
            REQUEST_PASSWORD_RESET_API, {
                userName: emailAddress
            })
            .then(() => {
                dispatch(reset('requestPasswordReset'));

                dispatch(addSuccessMessage("Email sent, please check your inbox"));
            })
            .catch((error) => {
                dispatch(addErrorMessage(utils.clearErrorMessage(error.response)));
            });
    }
}

export function changePassword(username, oldPassword, newPassword) {
    return function (dispatch) {
        axios.post(
            CHANGE_PASSWORD_API,
            {
                userName: username,
                oldPassword: oldPassword,
                newPassword: newPassword
            })
            .then(() => {
                dispatch(reset('changePassword'));

                dispatch(addSuccessMessage("Password change was successful"));
            })
            .catch((error) => {
                dispatch(addErrorMessage(utils.clearErrorMessage(error.response)));
            });
    }
}

export function validateToken(token) {
    return function (dispatch) {
        axios.get(
            `${VALIDATE_TOKEN_API}/${token}`)
            .then(response => {
                dispatch({
                    type: types.VALIDATE_TOKEN,
                    payload: response.data
                });

                dispatch(addSuccessMessage("Token is valid"));
            })
            .catch(() => {
                dispatch(addErrorMessage("Token is invalid or expired"));
            });
    }
}

export function clearTokenInfo() {
    return function (dispatch) {
        dispatch({
            type: types.CLEAR_TOKEN_INFO
        });
    }
}

export function register(user) {
    return function (dispatch) {
        axios.post(
            REGISTRATION_REST_API,
            user  )
            .then(response => {
                dispatch(reset('register'));

                dispatch(addSuccessMessage("User is registered"));
            })
            .catch((error) => {
                dispatch(addErrorMessage(utils.clearErrorMessage(error.response)));
            });
    }

}

export function requestAgentAccess(userName) {
    return function (dispatch) {
        axios.get(
            `${AGENT_ACCESS_API}/${userName}`)
            .then(response => {
                dispatch({
                    type: types.AGENT_ACCESS,
                    payload: response.data
                });
            })
            .catch((error) => {
                dispatch(addErrorMessage(utils.clearErrorMessage(error.response)));
            });
    }
}
