import types from "./types";

const initialState = {
    messages: []
};

export const toastMessagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_MESSAGE: {
            const newMessages = state.messages;
            newMessages.splice(0, 0, action.payload);
            return {
                ...state,
                messages: newMessages
            }
        }
        case types.REMOVE_MESSAGE: {
            const { messages } = state;
            const id = action.payload;

            return {
                ...state,
                messages: messages.filter(message => message.id !== id)
            }
        }
        default:
            return state;
    }
};