const test = {
    api: {
        PRODUCTS_REST_API_BASE: 'https://webservices.test.ontechsmartservices.com/ihs-api-gateway/direct-to-consumer/ihs-product-catalog',
        SCHEDULE_REST_SERVER: 'https://webservices.test.ontechsmartservices.com/ihs-api-gateway/direct-to-consumer/ihs-schedule-capacity',
        CUSTOMER_MANAGER_REST_API: 'https://webservices.test.ontechsmartservices.com/ihs-api-gateway/direct-to-consumer/ihs-customer-manager',
        ORDERS_REST_API_BASE: 'https://webservices.test.ontechsmartservices.com/ihs-api-gateway/direct-to-consumer/ihs-order-management',
        AGENT_ACCESS_API_BASE: 'https://webservices.test.ontechsmartservices.com/ihs-api-gateway/direct-to-consumer/ihs-agent-support',
        SHOPIFY_ACCOUNT_PAGE: 'https://www.test.ontechsmartservices.com/account',
        STORE_URL: 'https://www.test.ontechsmartservices.com/',
        LOGIN_URL: 'https://login-test.ontechsmartservices.com/'
    },
    BASE_URL: ''
};

const prod = {
    api: {
        PRODUCTS_REST_API_BASE: 'https://webservices.ontechsmartservices.com/ihs-api-gateway/direct-to-consumer/ihs-product-catalog',
        SCHEDULE_REST_SERVER: 'https://webservices.ontechsmartservices.com/ihs-api-gateway/direct-to-consumer/ihs-schedule-capacity',
        CUSTOMER_MANAGER_REST_API: 'https://webservices.ontechsmartservices.com/ihs-api-gateway/direct-to-consumer/ihs-customer-manager',
        ORDERS_REST_API_BASE: 'https://webservices.ontechsmartservices.com/ihs-api-gateway/direct-to-consumer/ihs-order-management',
        AGENT_ACCESS_API_BASE: 'https://webservices.ontechsmartservices.com/ihs-api-gateway/direct-to-consumer/ihs-agent-support',
        SHOPIFY_ACCOUNT_PAGE: 'https://www.ontechsmartservices.com/account',
        STORE_URL: 'https://www.ontechsmartservices.com/',
        LOGIN_URL: 'https://www.login.beta.ontechsmartservices.com'
    },
    BASE_URL: ''
};

let config = prod;
if (process.env.REACT_APP_STAGE === 'test') {
    config = test;
}

if (process.env.REACT_APP_STAGE === 'production') {
    config = prod;
}

export default {
    // Add common config values here
    START_DAY_PLUS: 3,
    PASSWORD_LENGTH: 8,
    MESSAGE_CLOSE_DELAY_MS: 3000,
    ...config
};
