import React, {Component} from 'react';
import dishLogo from './../../app/assets/images/brand-logo.png';
import userIcon from './../../app/assets/images/user-icon.png';
import config from '../../config';
import { Container, Navbar, NavbarBrand, Nav, NavItem } from 'reactstrap';

export default class HomeComponent extends Component {
    render() {
        const loggedIn = !!this.props.cookies.get('customerId');
        return (
            <header>
                <Navbar light expand="md" className="bb-1">
                    <Container className="header-container">
                        <NavbarBrand href={config.api.STORE_URL}>
                            <img className="logo-image" alt="OnTech" src={dishLogo} width="100px"/>
                        </NavbarBrand>
                        <Nav className="ml-auto icons-nav" navbar>
                            <NavItem>
                                <a className="nav-link" href={loggedIn ? `${config.api.STORE_URL}account` : config.api.LOGIN_URL }>
                                    <svg height="22px" version="1.1" viewBox="0 0 22 22" width="22px" xmlnsXlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                                        <g fillRule="evenodd" fill="none" id="Symbols" strokeWidth="1" stroke="none">
                                            <g id="Primary-Nav" transform="translate(-973.000000, -19.000000)">
                                                <image height="22" id="Account" width="20.625" x="973.6875" xlinkHref={userIcon} y="19" />
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </NavItem>
                            <NavItem>
                                <a className="nav-link" href={`${config.api.STORE_URL}cart`}
                                   style={{position: 'relative'}}>
                                    <svg aria-hidden="true" focusable="false" role="presentation" height="23px"
                                         version="1.1" viewBox="0 0 18 23" width="18px"
                                         xmlnsXlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                                        <g fillRule="evenodd" fill="none" strokeWidth="1" stroke="none">
                                            <g id="icon-cart" strokeWidth="1.5" stroke="#000010">
                                                <path
                                                    d="M9,5.58459473 C11.209139,5.58459473 13,5.94628906 13,5 C13,2.790861 11.209139,1 9,1 C6.790861,1 5,2.790861 5,5 C5,5.79833984 6.790861,5.58459473 9,5.58459473 Z"
                                                    id="Oval-2"/>
                                                <rect height="16.5" id="Rectangle-3" rx="3" width="16.5" x="0.75"
                                                      y="5.75"/>
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </NavItem>
                            <NavItem>
                                <a className="nav-ling mt-1" href="tel:1-833-ONTECH1"><strong>1-833-ONTECH1</strong></a>
                            </NavItem>
                        </Nav>
                    </Container>
                </Navbar>
            </header>
        )
    }
}

