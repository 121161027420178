import React, {Component} from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Header from './header';
import Scheduler from "./scheduler";
import ResetPassword from "./user/resetpassword";
import config from './../config';
import ToastMessages from "./common/toastmessages";
import './assets/styles/App.scss';
import HomeContainer from "./home/HomeContainer";
import Footer from "./footer";

class App extends Component {
    render() {
        return (
            <Router basename={`${process.env.PUBLIC_URL}`}>
                <div className="App">
                    <Header />
                    <main>
                        <div aria-live="polite" aria-atomic="true" className="status-message">
                            <div className="status-message-position">
                                <ToastMessages />
                            </div>
                        </div>
                        <Route exact path={`${config.BASE_URL}/`} component={HomeContainer} />
                        <Route path={`${config.BASE_URL}/reschedule`} component={Scheduler} />
                        <Route exact path={`${config.BASE_URL}/reset-password`} component={ResetPassword} />
                        <Footer />
                    </main>
                </div>
            </Router>
        );
    }
}

export default App;
