import React, {Component} from 'react';
import consts from './../consts';
import config from './../../../../config';
import PropTypes from "prop-types";
import cx from "classnames";
import './ToastMessage.css';

class ToastMessageComponent extends Component {

    componentDidMount() {
        const { id, autoClose, removeMessage } = this.props;

        if(autoClose) {
            this.timer = setInterval(() => removeMessage(id), config.MESSAGE_CLOSE_DELAY_MS);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    getTitle = (type) => {
        switch (type) {
            case consts.INFO_MESSAGE: return "Info:";
            case consts.WARN_MESSAGE: return "Warning:";
            case consts.ERROR_MESSAGE: return "Error:";
            case consts.SUCCESS_MESSAGE: return "Success:";
            default: return "Message:";
        }
    };

    getBorderStyle = (type) => {
        switch (type) {
            case consts.INFO_MESSAGE: return "border-info-color";
            case consts.WARN_MESSAGE: return "border-warn-color";
            case consts.ERROR_MESSAGE: return "border-error-color";
            case consts.SUCCESS_MESSAGE: return "border-success-color";
            default: return "";
        }
    };

    getBGStyle = (type) => {
        switch (type) {
            case consts.INFO_MESSAGE: return "bg-info-color";
            case consts.WARN_MESSAGE: return "bg-warn-color";
            case consts.ERROR_MESSAGE: return "bg-error-color";
            case consts.SUCCESS_MESSAGE: return "bg-success-color";
            default: return "";
        }
    };

    removeMessageHandle = () => {
        const { id, removeMessage } = this.props;
        removeMessage(id);
    };

    render() {
        const { text, type, autoClose } = this.props;

        return (
            <div className={cx("toast-message toast fade show", this.getBorderStyle(type))} role="alert" aria-live="assertive"
                 aria-atomic="true"
                 data-autohide={!!autoClose}>

                <div className={cx("toast-header text-color-black", this.getBGStyle(type))}>
                    <span className="mr-auto">{this.getTitle(type)}</span>
                    <button type="button" className="ml-2 mb-1 close" onClick={this.removeMessageHandle} data-dismiss="toast" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="toast-body">
                    {text}
                </div>
            </div>
        );
    }
}

ToastMessageComponent.propTypes = {
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    autoClose: PropTypes.bool.isRequired,
    removeMessage: PropTypes.func.isRequired
};

export default ToastMessageComponent;
