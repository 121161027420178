import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import SchedulerComponent  from './SchedulerComponent';
import { getCapacities, selectDay, selectTime, reschedule } from './redux/actions';
import {addErrorMessage} from "../common/toastmessages/redux/actions";

const mapStateToProps = state => {
    const { schedulerReducer } = state;

    return {
        days: schedulerReducer.days,
        times: schedulerReducer.times,
        selectedDate: schedulerReducer.schedule.date,
        selectedTime: schedulerReducer.schedule.name,
        rescheduled: schedulerReducer.rescheduled
    };
};

const  mapDispatchToProps = dispatch => {
    return bindActionCreators({ getCapacities, selectDay, selectTime, reschedule, addErrorMessage }, dispatch)
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SchedulerComponent);

