import types from "./types";

const initialState = {
    authenticationStatus: "",
    username: "",
    multipassRedirect: "",
    loggedIn: false,
    loginId: "",
    tokenValid: false,
    agentRedirect: '',
    passwordReset: false
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LOGIN: {
            const { authenticationStatus, username, multipassRedirect } = action.payload;

            return {
                ...state,
                authenticationStatus,
                username,
                multipassRedirect,
                loggedIn: true
            };
        }
        case types.LOGOUT: {
            return {
                ...state,
                loggedIn: false,
                authenticationStatus: "",
                username: "",
                passwordReset: false
            };
        }
        case types.RESET_PASSWORD: {
            return {
                ...state,
                tokenValid: false,
                loginId: "",
                passwordReset: false
            };
        }
        case types.RESET_PASSWORD_SUCCESSFUL: {
            return {
                ...state,
                passwordReset: true
            };
        }
        case types.CHANGE_PASSWORD: {
            return {
                ...state,
                loggedIn: false,
                passwordReset: false
            };
        }
        case types.VALIDATE_TOKEN: {
            const { loginId } = action.payload.params;
            return {
                ...state,
                tokenValid: true,
                loginId
            };
        }
        case types.CLEAR_TOKEN_INFO: {
            return {
                ...state,
                tokenValid: false,
                loginId: "",
                passwordReset: false
            };
        }
        case types.AGENT_ACCESS: {
            return {
                ...state,
                agentRedirect: action.payload.multipassRedirect
            };
        }
        default:
            return state;
    }
};
