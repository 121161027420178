import {connect} from "react-redux";
import {bindActionCreators} from 'redux'
import ResetPasswordComponent from './ResetPasswordComponent';
import {resetPassword} from '../redux/actions';
import {clearTokenInfo} from '../../user/redux/actions';
import {addErrorMessage} from '../../common/toastmessages/redux/actions';

const mapStateToProps = state => {
    const {userReducer} = state;
    return {
        initialValues: {password: '', confirmPassword: ''},
        loginId: userReducer.loginId,
        tokenValid: userReducer.tokenValid,
        passwordReset: userReducer.passwordReset
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({resetPassword, clearTokenInfo, addErrorMessage}, dispatch)
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPasswordComponent);
