import types from './types';
import axios from "axios";
import config from './../../../config';
import { addErrorMessage } from './../../common/toastmessages/redux/actions';
import utils from "../../utils";

export function getCapacities(startDate, zipCode, variantIds){
    return function(dispatch) {
        axios.get(
            `${config.api.SCHEDULE_REST_SERVER}/capacities`,
            {
                params: {
                    startDate,
                    zipCode,
                    variantIds
                }
            })
            .then(response => {
                dispatch({
                    type: types.GET_CAPACITIES,
                    payload: response.data
                });
            })
            .catch((error) => {
                dispatch(addErrorMessage(utils.clearErrorMessage(error.response)));
            })
    }
}

export function reschedule(orderId, scheduledDate, scheduledTime){
    return function(dispatch) {
        const transport = axios.create({
            withCredentials: true
        });

        transport.post(
            `${config.api.ORDERS_REST_API_BASE}/order/reschedule`,
            {
                clientOrderGuid: orderId,
                scheduleDate: scheduledDate,
                timeSlotLabel: scheduledTime
            })
            .then(() => {
                dispatch({
                    type: types.RESCHEDULED
                });
            })
            .catch((error) => {
                dispatch(addErrorMessage(utils.clearErrorMessage(error.response)));
            })
    }
}

export function selectDay(date){
    return function(dispatch) {
        dispatch({
            type: types.SELECT_DAY,
            payload: {date}
        });
    }
}

export function selectTime(name){
    return function(dispatch) {
        dispatch({
            type: types.SELECT_TIME,
            payload: name
        });
    }
}
