import React from 'react';
import PropTypes from "prop-types";

const InputFieldComponent = ({input, name, label, type }) => (
    <input {...input} name={name} placeholder={label} className="form-control border-0 p-3 h-auto" type={type}/>
);

InputFieldComponent.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
};

InputFieldComponent.defaultProps = {
    type: "text"
};

export default InputFieldComponent;
