import { parsePhoneNumberFromString } from 'libphonenumber-js'

const validatePasswordFormat = (value, field = "Password") => {
    let error = '';

    if (!value) {
        error = field + ' is required';
    } else if (value.length < 8) {
        error = field + ' must be at least 8 characters long.';
    } else if(!value.match(/[0-9]/g)) {
        error = field + ' must contain at least one number.';
    } else if(!value.match(/[a-z]/g)) {
        error = field + ' must contain at least one lowercase letter.';
    } else if(!value.match(/[A-Z]/g)) {
        error = field + ' must contain at least one uppercase letter.';
    } else if(!value.match(/[-!@#$%^&*()_+={}[.,~]/g)) {
        error = field + ' must contain at least one special character.';
    }
    return error;
};

const valueRequired = (value) => {
    let error = '';

    if (!value) {
        error = 'Required';
    }

    return error;
};

const validateEmail = (value) => {
    let error = '';
    if (!value) {
        error = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = 'Invalid email address';
    }

    return error;
};

const validatePasswordMatching = (password,confirmPassword) => {
    let error = '';
    if(password !== confirmPassword){
        error = 'Your passwords do not match.';
    }

    return error;
};


const validatePhone = (phone) => {
    const INVALID="Invalid phone number";
    const VALID="";
    const REQUIRED="Required";
    var phoneNumber = null;
    if (!phone) {
        return REQUIRED;
    } else {
        phoneNumber = parsePhoneNumberFromString(phone, 'US');
        var returnValue;
        if ( phoneNumber ) {
          if ( phoneNumber.isValid() ) {
            returnValue = VALID;
          }
          else if( phoneNumber.nationalNumber.length <= 10 ) {
              returnValue = INVALID;
          }
          else {
            returnValue = INVALID;
            console.log("valid phoneNumber && validatePhone::INVALID");
          }
        }
        else if ( !isNaN(phone)  && phone.length <= 10 ) {
            returnValue = VALID;
        }
        else {
          returnValue = INVALID;
          console.log("validatePhone::INVALID");
        }
        console.log("validatePhone: " + returnValue);
        return returnValue;
    }
};

const validatePostalCode = (value) => {
   return /^\d{5}(-\d{4})?$/.test(value);
};

const validatePartialPostalCode = (value) => {
   return /^\d{1,5}(-\d{0,4})?$/.test(value);
};

const clearErrorMessage = (value) => {
    return value && value.data && value.data.error ? value.data.error.messages : "";
};

function generateUniqId() {
    return new Date().getTime().toString();
}

const getPathFromUrl = (url) => {
    const parser = document.createElement('a');
    parser.href = url;
    return parser.pathname;
};

export default {
    validateEmail,
    validatePasswordFormat,
    validatePasswordMatching,
    validatePhone,
    validatePostalCode,
    validatePartialPostalCode,
    valueRequired,
    clearErrorMessage,
    generateUniqId,
    getPathFromUrl
};
