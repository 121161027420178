import types from "./types";

const initialState = {
    error: ''
};

export const errorReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.ADD_ERROR_MESSAGE: {
            return {
                ...state,
                error: action.payload
            };
        }
        default:
            return state;
    }
};
