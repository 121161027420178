import types from './types';
import consts from './../consts';
import utils from './../../../../app/utils';

export function removeMessage(id){
    return function(dispatch) {
        dispatch({
            type: types.REMOVE_MESSAGE,
            payload: id
        });
    }
}

export function addErrorMessage(message){
    return function(dispatch) {
        dispatch({
            type: types.ADD_MESSAGE,
            payload: {
                id: utils.generateUniqId(),
                type: consts.ERROR_MESSAGE,
                text: message,
                autoClose: true
            }
        });
    }
}

export function addInfoMessage(message){
    return function(dispatch) {
        dispatch({
            type: types.ADD_MESSAGE,
            payload: {
                id: utils.generateUniqId(),
                type: consts.INFO_MESSAGE,
                text: message,
                autoClose: true
            }
        });
    }
}

export function addWarnMessage(message){
    return function(dispatch) {
        dispatch({
            type: types.ADD_MESSAGE,
            payload: {
                id: utils.generateUniqId(),
                type: consts.WARN_MESSAGE,
                text: message.toString(),
                autoClose: true
            }
        });
    }
}

export function addSuccessMessage(message){
    return function(dispatch) {
        dispatch({
            type: types.ADD_MESSAGE,
            payload: {
                id: utils.generateUniqId(),
                type: consts.SUCCESS_MESSAGE,
                text: message.toString(),
                autoClose: true
            }
        });
    }
}


