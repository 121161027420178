import React from 'react';
import PropTypes from 'prop-types';
import './FooterLink.css';

const FooterLinkComponent = ({title, url}) => (
    <p className="mb-0 FooterLink">
        <small>
            <a href={url}>{title}</a>
        </small>
    </p>
);

FooterLinkComponent.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
};

export default FooterLinkComponent;
